<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {formatNumber} from '@/utils/formatter'
import useHttp from '@/comp-functions/useHttp'
import Swal from "sweetalert2";

import { ref, onMounted } from '@vue/composition-api'

export default {
  page: {
    title: "Flight Schedule Detail",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    //RecentActivity,
    Layout, PageHeader
  },
  props:{
      schedule_id: String
  },
  data(){
    return {
      title: "Flight Schedule Detail",
      items: [],
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true
    };
  },

  setup(props){
    const { $get } = useHttp()
    const { $put } = useHttp()

    const selectedSchedule = ref({})
     const setClosingsale = async () => {
      Swal.fire({
        title: "Are you sure ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, closing sale!"
      }).then(result => {
        if (result.value) {
          let dataSubmit = {
            schedule_id : selectedSchedule.value.sch_id
          }
          $put({ url: 'master/schedule_salestatus',
            data: dataSubmit
          })
          Swal.fire({
            icon: "success",
            title: 'Sales status has been closed',
            showConfirmButton: true,
            timer: 2600,
            timerProgressBar: true
          });
          getSchedule()
        }
      });

    }

    const getSchedule = async () => {
      const {data} = await $get({ url: `master/schedule_v2/${props.schedule_id}`})
      selectedSchedule.value = data
    }

    onMounted(() => {
     getSchedule()
    })

return{
  getSchedule,
  selectedSchedule,
  setClosingsale,
  formatNumber
}
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-2">
                  <label class="col-sm-3">Airlines</label>
                  <label class="col-sm-9">{{selectedSchedule.vnd_name}}</label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Flight</label>
                  <label class="col-sm-9">{{selectedSchedule.sch_flight_no}}</label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Route</label>
                  <label class="col-sm-9">{{selectedSchedule.oriprt_locationname}} ({{selectedSchedule.oriprt_initial}}) <i class="ri-arrow-right-line"></i>  {{selectedSchedule.desprt_locationname}} ({{selectedSchedule.desprt_initial}})</label>
                </div>

                <div class="form-group mb-2">
                  <label class="col-sm-3">Schedule</label>
                  <label class="col-sm-9">{{selectedSchedule.sch_departure_date }} {{ selectedSchedule.sch_departure_time }} <i class="ri-arrow-right-line"></i> {{selectedSchedule.sch_arrival_time}}</label>
                </div>

                <div class="form-group mb-2">
                  <label class="col-sm-3">Duration</label>
                  <label class="col-sm-9">{{Math.floor(selectedSchedule.sch_duration/60)}}h {{Math.floor(selectedSchedule.sch_duration%60)}}m </label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-2">
                  <label class="col-sm-3">Capacity</label>
                  <label class="col-sm-9">{{selectedSchedule.sch_capacity}} of {{selectedSchedule.sch_availablecapacity}}</label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Tariff PerKg</label>
                  <label class="col-sm-9">{{formatNumber(selectedSchedule.sch_price_kg)}}</label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Transit</label>
                  <label class="col-sm-9"><div v-if="selectedSchedule.sch_transit > 0">{{selectedSchedule.sch_transit}} Transit</div>
                    <div v-else>Direct</div></label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Schedule Type</label>
                  <label class="col-sm-9">Regular</label>
                </div>
                <div class="form-group mb-2">
                  <label class="col-sm-3">Sale Status</label>
                  <label class="col-sm-9">
                    <div v-if="selectedSchedule.sch_is_on_sale === true">Open</div>
                    <div v-else>Closed</div>


                  </label>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <!-- <b-button variant="primary">
                    Closing - Sale
                  </b-button> -->
                    <b-button v-if="selectedSchedule.sch_is_on_sale === true" variant="primary" @click.prevent="setClosingsale">
                      Closing - Sale
                    </b-button>
                   <router-link 
                    tag="a"
                    :to="{name: 'apps-upd-schedule', params: {schedule_id : selectedSchedule.sch_id }}"
                    class="btn btn-primary"
                    v-b-tooltip.hover title="Update Flight Status"
                  >Update Flight Status</router-link>
                  <router-link 
                    tag="a"
                    :to="{name: 'apps-upd-capacity', params: {id_schedule : selectedSchedule.sch_id }}"
                    class="btn btn-primary"
                    v-b-tooltip.hover title="Update Flight Capacity"
                  >Update Capacity</router-link>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
<!--
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Customer</th>
                    <th>SMU No.</th>
                    <th>Collies</th>
                    <th>Total Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>PT A Express</td>
                    <td>000-0000001</td>
                    <td>3</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>PT B Logistics</td>
                    <td>000-0000001</td>
                    <td>2</td>
                    <td>300</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <div class="row">
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
    </div>-->
  </Layout>
</template>